import { digitalLibraryTemplateServiceTypes } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { groq } from "next-sanity";
import { resolveDigitalLibraryServiceSummary } from "@libry-content/common";
import { ContentSearcher } from "./searchContent";

// Gir mulighet til å gi treff ved søk på nøkkelord som ikke finnes i dataen
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "tilbud tjenester digitalt digitale",
  nn: "tilbud tjenester digitalt digitale",
};

export const searchDigitalLibraryServices: ContentSearcher = {
  filter: (locale) => {
    const templateDigitalServicesQuery = groq`
      *[
        _type in ${JSON.stringify(digitalLibraryTemplateServiceTypes)}
        && (
          template->name.${locale} match $searchQuery ||
          template->teaser.${locale} match $searchQuery ||
          "${customMatchStrings[locale]}" match $searchQuery
        )
     ]
      {
        ...,
        "_score": 5 // Didn't find a way to make resolved references in boost-function work with scoring, so we just give a fixed score of 5 if name or teaser matches searchQuery
      }`;

    const customDigitalServicesQuery = groq`
      *[_type == "digitalLibraryCustomService"]
        | score(
          boost(name.${locale} match $searchQuery, 10),
          boost(_type == "digitalLibraryCustomService" && "${customMatchStrings[locale]}" match $searchQuery, 5), // _type == "digitalLibraryCustomService" is a hack to make content lake give an appropriate score (without it we always get a score of 1)
          boost(teaser.${locale} match $searchQuery, 5),
          boost(pt::text(description.${locale}) match $searchQuery, 1),
        )
        [_score > 0]`;

    return groq`[...${templateDigitalServicesQuery}, ...${customDigitalServicesQuery}]`;
  },
  resolver: groq`
    _type in ${JSON.stringify(digitalLibraryTemplateServiceTypes)} => {${resolveDigitalLibraryServiceSummary}},
    _type == "digitalLibraryCustomService" => {${resolveDigitalLibraryServiceSummary}}
  `,
};
